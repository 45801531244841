import request from '../utils/request';
import base from './base';
export async function currentAccount() {
  return request({
    url: base['url'] + '/admin/account/current',
    method: 'GET'
  });
}
export async function loginByAccount(params) {
  return request({
    url: base['url'] + '/admin/account/login',
    method: 'POST',
    data: params
  });
}
export async function accountList(params) {
  return request({
    url: base['url'] + '/admin/account/list',
    method: 'POST',
    data: params
  });
}
export async function accountAdd(params) {
  return request({
    url: base['url'] + '/admin/account/add',
    method: 'POST',
    data: params
  });
}
export async function AccountDel(params) {
  return request({
    url: base['url'] + '/admin/account/del',
    method: 'POST',
    data: params
  });
}
export async function AccountByName(params) {
  return request({
    url: base['url'] + '/admin/account/get',
    method: 'POST',
    data: params
  });
}
export async function fetchUserList() {
  return request({
    url: base['url'] + '/admin/user/list',
    method: 'GET'
  });
}
export async function checkUser(params) {
  return request({
    url: base['url'] + '/admin/user/check',
    method: 'POST',
    data: params
  });
}